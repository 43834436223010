<template>
  <div id="container">

    <section id="register">
      <div class="wrap">
        <div class="register_step flex">
          <div class="step_box flex on">
            <div class="num">1</div>
            <p>塾生情報の登録</p>
          </div>
          <div class="step_box flex on">
            <div class="num">2</div>
            <p>ご住所の登録</p>
          </div>
          <div class="step_box flex">
            <div class="num">3</div>
            <p>パスワード設定</p>
          </div>
          <div class="step_box flex">
            <div class="num">4</div>
            <p>入力内容の確認</p>
          </div>
          <div class="step_box flex">
            <div class="num">5</div>
            <p>初回月謝の<br>支払いへ進む</p>
          </div>
        </div>

        <form @submit="nextClicked">

          <div class="cts_box">
            <h2 class="sub_ttl"><span>ご住所の登録</span></h2>
            <p class="reg_lead">
              以下のすべての項目に入力してください。<br><br>
              <span class="red bold">※必ず郵便物をお受け取りできる住所をご登録下さい。<br>郵便物を別住所に送付したい場合は、下段の□にチェックを入れて所定フォームに別途ご登録下さい。<br><br>
              <span class="blue bold">「*」のついている箇所は必須項目です。</span>
              </span>
            </p>

            <form-error-box v-if="errors"/>

            <div class="reg_set">
              <div class="input_set addr">
                <div class="label">郵便番号<span>*</span></div>
                <p>（半角数字）</p>
                <div class="input_fields flex">
                  <div class="field">
                    <input type="text" v-model="new_user.zip" v-bind:class="{error: validation_errors.zip}" name="zip"
                           @input="new_user.zip = replaceFullToHalf(new_user.zip)"
                           placeholder="例：100-1234">
                    <validation-errors :errors="validation_errors.zip" v-if="validation_errors.zip"></validation-errors>
                  </div>
                  <div class="field">
                    <button type="button" class="ajaxzip3" v-on:click="zipSearch">郵便番号から住所を取得</button>
                  </div>
                </div>
              </div>

              <div class="input_set addr">
                <div class="label">都道府県名<span>*</span></div>
                <p>（選択して下さい ※海外にお住まいの方は「日本国外に居住」を選択してください）</p>
                <PrefectureSelect :prefecture="new_user.prefecture" name="prefecture"
                                  v-bind:class="{error: validation_errors.prefecture}"
                                  v-on:changed="prefectureChanged"/>
                <validation-errors :errors="validation_errors.prefecture"
                                   v-if="validation_errors.prefecture"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">市区町村<span>*</span></div>
                <input type="text" v-model="new_user.address1" v-bind:class="{error: validation_errors.address1}"
                       @input="new_user.address1 = replaceHalfToFull(new_user.address1)"
                       name="address1" placeholder="例：新宿区">
                <validation-errors :errors="validation_errors.address1"
                                   v-if="validation_errors.address1"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">地名・番地<span>*</span></div>
                <input type="text" v-model="new_user.address2" v-bind:class="{error: validation_errors.address2}"
                       @input="new_user.address2 = replaceHalfToFull(new_user.address2)"
                       placeholder="例：３丁目１３番地">
                <validation-errors :errors="validation_errors.address2"
                                   v-if="validation_errors.address2"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">建物名・部屋番号<span></span></div>
                <input type="text" v-model="new_user.address3" v-bind:class="{error: validation_errors.address3}"
                       @input="new_user.address3 = replaceHalfToFull(new_user.address3)"
                       placeholder="例：複眼ビル１１１号室">
                <validation-errors :errors="validation_errors.address3"
                                   v-if="validation_errors.address3"></validation-errors>
              </div>

              <div class="input_set">
                <div class="checkbox">
                  <input id="check03" type="checkbox" v-model="new_user.enable_primary_address" value="1"
                         v-on:change="enablePrimaryAddressClicked()">
                  <label for="check03" class="bold blue">郵便物を別住所で受け取りたい方はチェックを入れて、郵便物等の送付先住所指定フォームに別住所を指定してください</label>
                </div>
              </div>
            </div>

            <div class="reg_set option" v-show="new_user.enable_primary_address">
              <h3 class="min_ttl">郵便物等の送付先住所指定（任意：登録ご住所と同じ場合は入力は不要です）</h3>
              <p class="reg_lead">
                オンラインショップの商品や複眼経済塾からの各種お知らせなど、塾生登録住所以外への送付を希望する場合はご入力ください。<br>塾生登録住所以外で郵便物などを送付する住所のご希望がない場合は入力をスキップしてください。<br><br>
                <span class="blue bold" v-if="false">「*」のついている箇所は必須項目です。</span>
              </p>

              <div class="input_set addr">
                <div class="label">郵便番号</div>
                <p>（半角数字）</p>
                <div class="input_fields flex">
                  <div class="field">
                    <input type="text" v-model="new_user.opt_zip" v-bind:class="{error: validation_errors.opt_zip}"
                           @input="new_user.opt_zip = replaceFullToHalf(new_user.opt_zip)"
                           name="opt_zip" placeholder="例：100-1234">
                    <validation-errors :errors="validation_errors.opt_zip"
                                       v-if="validation_errors.opt_zip"></validation-errors>
                  </div>
                  <div class="field">
                    <button type="button" class="ajaxzip3 option" v-on:click="optZipSearch">郵便番号から住所を取得</button>
                  </div>
                </div>
              </div>

              <div class="input_set addr">
                <div class="label">都道府県名</div>
                <p>（選択して下さい ※海外にお住まいの方は「日本国外に居住」を選択してください）</p>
                <PrefectureSelect :prefecture="new_user.opt_prefecture" name="opt_prefecture"
                                  v-bind:class="{error: validation_errors.opt_prefecture}"
                                  v-on:changed="optPrefectureChanged"/>
                <validation-errors :errors="validation_errors.opt_prefecture"
                                   v-if="validation_errors.opt_prefecture"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">市区町村</div>
                <input type="text" v-model="new_user.opt_address1"
                       @input="new_user.opt_address1 = replaceHalfToFull(new_user.opt_address1)"
                       v-bind:class="{error: validation_errors.opt_address1}" name="opt_address1" placeholder="例：新宿区">
                <validation-errors :errors="validation_errors.opt_address1"
                                   v-if="validation_errors.opt_address1"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">地名・番地</div>
                <input type="text" v-model="new_user.opt_address2"
                       @input="new_user.opt_address2 = replaceHalfToFull(new_user.opt_address2)"
                       v-bind:class="{error: validation_errors.opt_address2}" placeholder="例：３丁目１３番地">
                <validation-errors :errors="validation_errors.opt_address2"
                                   v-if="validation_errors.opt_address2"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">建物名・部屋番号<span></span></div>
                <input type="text" v-model="new_user.opt_address3"
                       @input="new_user.opt_address3 = replaceHalfToFull(new_user.opt_address3)"
                       v-bind:class="{error: validation_errors.opt_address3}" placeholder="例：複眼ビル１１１号室">
                <validation-errors :errors="validation_errors.opt_address3"
                                   v-if="validation_errors.opt_address3"></validation-errors>
              </div>

            </div>

            <p class="alnC mb20">
              すべての項目に入力したらこちらのボタンを押してください。
            </p>
            <div class="button_wrap">
              <input type="submit" class="submit light arrow" value="パスワードの設定">
            </div>
          </div>

        </form>

      </div><!--//.wrap-->
    </section>
  </div><!--//＃container-->
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import ValidationErrors from '@/components/ValidationErrors.vue'
import PrefectureSelect from '@/components/PrefectureSelect.vue'
import FormErrorBox from '@/elements/FormErrorBox.vue'

export default {
  mixins: [Libraries],
  components: {
    ValidationErrors,
    PrefectureSelect,
    FormErrorBox,
  },
  data() {
    if (!this.$store.state.user) {
      this.$router.push({
        name: 'UsersRegistrationStudent',
        params: this.$route.params
      })
      return
    }
    this.$store.state.user.password_validates = false
    let _new_user = {}
    for (const [key, value] of Object.entries(this.$store.state.user)) {
      _new_user[key] = value
    }
    return {
      new_user: _new_user,
      validation_errors: {},
      errors: null,
    }
  },
  methods: {
    prevClicked() {
      this.$router.push({
        name: 'UsersConfirmationStudentPage1',
        params: this.$route.params
      })
    },
    nextClicked(event) {
      const keys = [
        'zip', 'prefecture', 'address1', 'address2', 'address3',
        'opt_zip', 'opt_prefecture', 'opt_address1', 'opt_address2', 'opt_address3',
      ]

      event.preventDefault()
      this.validation_errors = {}
      this.errors = null
      this.$store.state.user = this.new_user

      this.axios
          .post(`${this.env.api_base_url}users/validate.json`, {
            user: this.new_user,
          })
          .then(() => {
            this.$router.push({
              name: 'UsersConfirmationStudentPage3',
              params: this.$route.params
            })
          })
          .catch((error) => {
            this.scrollTop()
            if (error.response.data.errors) {
              keys.forEach(key => {
                if (error.response.data.validation_errors[key]) {
                  this.validation_errors[key] = error.response.data.validation_errors[key]
                }
              })
              if (Object.keys(this.validation_errors).length === 0) {
                this.$router.push({
                  name: 'UsersConfirmationStudentPage3',
                  params: this.$route.params
                })
              } else {
                this.errors = true
              }
            }
            if (error.response.data && error.response.data.error) {
              // TODO: エラー処理
              // this.errors = [error.response.data.error]
            }
            this.$forceUpdate();
          });
    },
    prefectureChanged(prefecture) {
      this.new_user.prefecture = prefecture
      //this.$forceUpdate()
    },
    optPrefectureChanged(prefecture) {
      this.new_user.opt_prefecture = prefecture
      //this.$forceUpdate()
    },
    zipSearch() {
      if (this.new_user.zip) {
        this.addressSearch(this.new_user.zip)
            .then(data => {
              this.new_user.prefecture = data.pref
              this.new_user.address1 = data.address
              this.$forceUpdate()
            })
      }
    },
    enablePrimaryAddressClicked() {
      if (this.new_user.enable_primary_address) {
        this.new_user.opt_zip = this.new_user.zip
        this.new_user.opt_prefecture = this.new_user.prefecture
        this.new_user.opt_address1 = this.new_user.address1
        this.new_user.opt_address2 = this.new_user.address2
        this.new_user.opt_address3 = this.new_user.address3
        this.$forceUpdate()
      }
    },
    optZipSearch() {
      if (this.new_user.opt_zip) {
        this.addressSearch(this.new_user.opt_zip).then(data => {
          this.new_user.opt_prefecture = data.pref
          this.new_user.opt_address1 = data.address
          this.$forceUpdate()
        })
      }
    }
  }
}
</script>
